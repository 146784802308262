import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ['toggle'];

  toggle() {
    this.element.classList.toggle('bg-teal-600');
    this.toggleTarget.classList.toggle('translate-x-5');

    if (this.element.dataset.fieldTarget) {
      if (this.element.classList.contains('bg-teal-600')) {
        document.getElementById(this.element.dataset.fieldTarget).value = this.element.dataset.fieldValue;
      } else {
        document.getElementById(this.element.dataset.fieldTarget).value = null;
      }
    }
  }
}
